<template>
  <div>
      <!-- <div class="buyAdmin">
        <div class="cardBox">
          <div class="cardItem">
            <img src="../../assets/sucaibao.png" class="buyimg"/>
            <strong>1000+份团长资料 + 智能运营机器人</strong>
            <h2>￥19.9</h2>
            <div class="buyBtn" @click=paybtns(1)>立即购买</div>
          </div>
        </div>
        <div class="cardBox">
          <div class="cardItem">
            <img src="../../assets/jiqiren.png" class="buyimg"/>
            <strong>智能运营机器人</strong>
            <h2>￥9.9</h2>
            <div class="buyBtn" @click=paybtns(2)>立即购买</div>
          </div>
        </div>
      </div> -->
      
      <!-- <div class="buyAdminList">
        <el-table
        :data="payList"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="index"
          width="40" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="productsTitle"
          label="购买套餐名称"
          width="250">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="购买时间"
          width="150"
        >
        </el-table-column>
         <el-table-column
          prop="productsPrice"
          label="支付金额" width="100">
        </el-table-column>
         <el-table-column
      label="下载地址与教程"
       header-align="center" align="left">
      <template slot-scope="scope">
        <p><bdo><a href="https://tooguo.com/kaituanyi/WX-3.2.1.154.exe" target="_blank">【机器人基础版】</a></bdo>
        <bdo v-if="scope.row.productsId==1000" class="pload"><span><a href="http://tooguo.com/kaituanyi/480tuanzhang.zip" target="_blank">下载【团长资料】</a></span></bdo>
        <bdo class="pload"><span><a href="http://tooguo.com/kaituanyi/%E6%8E%A5%E5%8D%95%E5%8A%A9%E6%89%8Bv1.8.exe" target="_blank">下载【机器人】</a></span></bdo></p>
      </template>
    </el-table-column>
      </el-table>
      </div>   -->

       <div class="contents">
      <div class="tableSearch">
          <div class="classObj"><el-button @click="shouquan" type="primary" icon="el-icon-search">新建机器人</el-button></div>
          <el-form :inline="true" ref="searchForm" :model="searchForm"  label-width="100px">
             <el-form-item label="机器人名称" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable  style="width:190px"
                placeholder="请输入机器人名称"></el-input>
            </el-form-item>
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
          </el-form>
      <!-- </div>
    <div class="mainbox" style="padding: 10px"> -->
        <div class="cardList">
       <div class="cardBox" v-for="(item, index) in tableData" v:key="item.id">
           <div class="cardItem">
             <img :src="renurl" class="renSize" />
             <strong>{{item.name}}</strong>
              <p>机器人编号: {{item.botId}}</p>
             <p>最近活跃：{{item.updateTime}}</p>
             <p>创建时间：{{item.createTime}}</p>
             <div class="statusbox">
               <span class="qidong" v-if="item.status == 1"><i class="el-icon-video-play"></i> 已启动</span>
               <span class="huise" v-else-if="item.status == 2"><i class="el-icon-switch-button"></i> 已离线</span>
               <span v-else class="yell"><i class="el-icon-loading"></i> 创建中 (预计需要2分钟，跟好友聊聊天会加快进度哦)</span>
             </div>
             <div class="caozuobtns">
              <el-button v-if="item.status==1" @click="contactClick(item)" type="primary" plain size="small">联系人设置</el-button>
              <el-button v-if="item.status==1" @click="qunClick(item)" type="primary" plain size="small">群组设置</el-button>
              <!-- <el-button @click="loginClick(item)" type="primary"  plain size="small">重新启动</el-button> -->
             </div>
           </div>
       </div> 
        </div> 
      <div class="paginations">
          <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      </div>
    </div>

     <el-dialog title="绑定机器人"  :before-close="handleClose"  width="600px" :visible.sync="visiable">
      <el-form ref="formAdd" :model="formAdd" label-position="right" :rules="rules" label-width="120px"
        style="width:480px;">
        <el-form-item label="机器人编号" prop="botId">
          <el-input v-model="formAdd.botId" autocomplete="off" maxlength="30" placeholder="请输入机器人编号"></el-input>
        </el-form-item>
        <div class="midCss">
          <el-button type="primary" :loading="binding" @click="handleSubmit('formAdd')" size="medium">提交</el-button>
          <el-button @click="handleClose" size="medium">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>

     <el-dialog title="微信扫一扫购买套餐"  :before-close="handleClose"  width="480px" :visible.sync="flagVisiable">
       <div class="shopboxs" id="qrCode" ref="qrCodeUrl"></div>
       <div class="midCss">
       <el-button type="primary" @click="payqian" size="medium">我已完成付款</el-button>
       </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from'qrcodejs2'
import { getBotList, addRobot, robotbuyorder, getBuyList } from "@/api/robot";
import passWord from "@/components/password";
import jiqiren from "@/assets/ren03.jpg"
let timeVal, qrcode
export default {
  name: 'Orderss',
  components: { passWord },
  data() {
    return {
      searchForm: {
        name: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      shopflag: false,
      isShows: false,
      timeNum: 0,
      shopArr: [],
      payList: [],
      renurl: jiqiren,
      loading: false,
      flagVisiable: false,
      scanData: '',
      activeName: 'first',
      rows: '',
      tableData: [],
      multipleSelection: [],
      visiable: false,
      binding: false,
      formAdd: {
        botId: ''
      },
      rules: {
        botId: [{ required: true, message: '请输入机器人编号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleSubmit(formName) {
      const that = this;      
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.binding = true
          addRobot(that.formAdd).then(res => {
            console.log(res);
            if(res.code === 200) {
              that.binding = false
              that.$message.success(res.message)
              that.handleClose()
              that.getTopSearch()
            }else{
              that.$message.error(res.message)
            }
            that.binding= false
          })
        }
      })    
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClose(done){
      this.visiable = false
      this.flagVisiable = false
      qrcode.clear()
      this.getTopSearch()
      document.getElementById('qrCode').innerHTML= ''
      // this.$confirm('请确认机器人登录成功再关闭此窗口，否则可能会登录失败，确认关闭？').then(_ => {
      //   this.shopflag = false;
      //   this.isShows = false;
      //   this.timeNum = 0;
      //   this.getTopSearch()
      //   clearInterval(timeVal)
      // }).catch(_ => {});
    },
    getTopSearch(e){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      getBotList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data.rows
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getPlat(e){
      var that = this
      that.loading = true
      that.visiable = true
      // addRobot().then(res => {
      //   console.log(res);
      //   if(res.code ===200) {
      //     that.loading = false
      //     that.scanData = res.data

      //   }else{
      //     this.$message.error(res.message)
      //   }
      //   that.loading= false
      // })
    },
    paybtns(e){
      var that = this
      that.loading = true
      that.flagVisiable = true
      var datas = {
        "productId": e==1?'1000':'1001',
        "productPrice": e==1?'19.9':'9.9',
        "productTitle": e==1?'1000+份团长资料 + 智能运营机器人':'智能运营机器人'
      }
      robotbuyorder(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.creatQrCode(res.data.codeUrl)
        }else{
          that.$message.error(res.message)
        }
        that.loading= false
      })
    },
    payqian(){
      var that = this
      // that.flagVisiable = true
       getBuyList().then(res => {
        console.log(res);
        if(res.code ===200) {
          that.payList = res.data
          that.flagVisiable = false
        }else{
          that.$message.error(res.message)
        }
        that.loading= false
      })
    },
    chongPlat(row){
      var that = this
      that.loading = true
      var datas = {
        botId: row.botId
      }
      scanBarCode(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.creatQrCode(res.data.barcode)
          timeVal = setInterval(() =>{
            that.getLoginStatus(res.data.botId, timeVal)
          }, 3000)
          
        }else{
          that.$message.error(res.message)
        }
        that.loading= false
      })
    },
    getLoginStatus(ids, aa){
      var that = this
      var datas = {
        botId: ids
      }
      console.log(that.timeNum)
      robotisLogin(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.timeNum++
          that.scanData = res.data
          if(res.data.state == 0){
            that.shopflag = false;
            that.getTopSearch()
            clearInterval(aa)
          }
          if(that.timeNum > 200){
            that.isShows = true
            that.timeNum = 0;
            clearInterval(aa)
          }
        }
      })
    },
    creatQrCode(texts) {
      var that = this;
      qrcode =new QRCode(that.$refs.qrCodeUrl, {
          text: texts,
          width: 200,
          height: 200,
          colorDark:'#000000',
          colorLight:'#ffffff',
          correctLevel: QRCode.CorrectLevel.H
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    refresh(){
      var that = this
      if(that.rows && that.rows.botId){
        that.chongPlat(that.rows)
      }else{
        that.getPlat()
      }
      that.timeNum = 0
      that.isShows = false
    },
    shouquan(){
      var that = this
      that.shopflag = true;
      that.isShows = false;
      that.timeNum = 0;
      that.getPlat()
      that.rows = ''
    },
    contactClick(row){
      this.$router.push({
        path: "/reboter/contact?id=" + row.botId
      })
    },
    qunClick(row){
      this.$router.push({
        path: "/reboter/group?id=" + row.botId
      })
    },
    loginClick(row){
      var that = this
      that.shopflag = true;
      that.isShows = false;
      that.timeNum = 0;
      that.rows = row
      that.chongPlat(row)
    }
  },
  mounted(){
    this.getTopSearch()
    this.payqian()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.cardList{
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (max-width: 1338px){
  .cardList{
    width: 844px;
  }
}
@media screen and (min-width: 1338px){
  .cardList{
    width: 1266px;
  }
}
@media screen and (min-width: 1920px){
  .cardList{
    width: 1688px;
  }
}
.cardBox{
  float: left;
  width: 400px;
  overflow: hidden;
}
.cardItem{
  margin: 12px;
  display: block;
  background-color: #fff;
  padding: 20px 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
}
.cardItem strong{
  display: block;
  text-align: center;
  line-height:44px;
  padding: 10px 0;
  margin-bottom: 10px;
}
.caozuobtns{
  display: block;
  text-align: center;
  padding: 10px 0;
}
.cardItem p{
  font-size: 12px;line-height:22px; text-align: center; color: #999;
}
.renSize{
  width: 120px; height: 120px; padding:20px; background-color: #f3f3f3;display: block; margin: 0 auto; border-radius: 100px; box-shadow: 0px 8px 0 #ccc;
}
.statusbox{
  position: absolute;
  left: 0; top: 0;
  font-size: 12px;
  display: block;
  overflow: hidden;
}

.statusbox .qidong{
  background-color: #07C160;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
}

.statusbox .huise{
  background-color: #eee;
  color: #999;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
}
.statusbox .yell{
  background-color: #ff7200;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  display: block;
}

.classObj{
  float:right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.shopLogo{
  display: block;
  overflow: hidden;
}
.shopLogo p{
  line-height:44px;
  display: block;
  text-align: center;
  height: 44px;
}
.shopol{
  width: 200px;
  height: 200px;
  display: block;
  overflow: hidden;
}
.shopboxs{
  width: 200px;
  height: 200px;
  display: block;
  border: 1px solid #eee;
  padding: 10px;
  margin: 0 auto 10px;
  cursor: pointer;
  background-color:#fff; 
  position:relative;
}

.yishixiao{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0,0,0,0.7);
  display: block;
  z-index: 999;
  text-align: center;
}
.yishixiao p{
  line-height:44px;
  padding-top: 60px;
  font-size: 16px;color: #fff;
}
.refeishi{
  width: 100px;
  height: 30px;
  border: 1px solid #eee;
  border-radius: 2px;
  color: #fff;
  line-height:30px;
  margin: 0 auto;
}
.buyBtn{
  display: inline-block;
  padding: 0 22px;
  font-size: 15px;
  line-height:32px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
.buyimg{
  width: 200px;
  height: 177px;
  display: block;
  margin: 0 auto;
}
.buyAdmin{
  display: block;
  padding: 0 10px;
  overflow: hidden;
  text-align: center;
}
.buyAdminList{
  margin: 10px;
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
}
.buyAdmin h2{
  color: #ff0000;
  font-size: 24px;display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: -20px;
  margin-bottom: 10px;
}
.pload{
  line-height:24px;
  font-size: 12px;
}
.pload span{
  width: 300px;
  padding-left: 20px;
}
</style>